import React from 'react';

import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { css } from '@emotion/core';
import PropTypes from 'prop-types';

import Header from 'components/header/header';
import Layout from 'components/layout';

import CorporateSubmitForm from 'components/corporate-submit-form';
import CorporateRedBox from 'components/redBox';
import PageMeta from 'components/page-meta/page-meta';

import { mq } from 'styles/breakpoints';
import { ContentBox, CorporateBox } from 'styles/boxes.css';
import { H1, H3, FocusH3 } from 'styles/headers.css';
import { BoxP, P } from 'styles/text.css';

let headerWrapper = css`
  width: 100%;
  text-align: center;
  align-items: center;
  position: relative;
  height: 70%;
  color: white;
  font-weight: 600;
  display: table;
`;

let headerImg = css`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
`;

let bodyCopy = css`
  margin-top: 3rem;
  fontsize: 1.5rem;
  ${mq.desktop} {
    margin-top: 6rem;
  }
`;

let headerFont = css`
  vertical-align: bottom;
  display: table-cell;
  font-size: 3rem;
  padding-bottom: 2rem;
  ${mq.desktop} {
    font-size: 5rem;
  }
`;

let paddingCopy = css`
  padding: 3rem;
  margin: auto;
  ${mq.desktop} {
    padding: 6rem;
  }
`;

let row = css`
  display: flex;
  flex-direction: row;
  margin-bottom: 4rem;
`;

let packageImg = css`
  width: 70%;
  position: absolute;
`;

let letsTalk = css`
  padding-top: 3rem;
  ${mq.desktop} {
    padding-top: 6rem;
  }
`;

let formVerifyCss = css`
  margin: 3rem 5rem 1rem 5rem;
  padding-bottom: 8rem;
  color: green;
`;

export class CorporateLandingTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
    };
    this.setSubmitTrue = this.setSubmitTrue.bind(this);
  }

  setSubmitTrue() {
    this.setState({ submitted: true });
  }

  render() {
    const { studioName, studioSlug } = this.props.pageContext;
    const { data } = this.props;
    const apiUrl = data.site.siteMetadata.apiUrl;
    const headerImage = data.headerImage.childImageSharp.fluid;
    const locationImage = data.locationImage.childImageSharp.fluid;
    const dropIn = data.dropIn.childImageSharp.fluid;
    const inYourHouse = data.inYourHouse.childImageSharp.fluid;
    const formVerify = data.formVerify.childImageSharp.fluid;
    const pageTitle = `Gloveworx ${studioName} Corporate Package Options`;
    const pageDescription = `Find out about the Corporate Package Options offered at Gloveworx ${studioName}. Don't see a package that fits your needs?
    Get in touch with us to craft a custom package option for your organization.`;

    return (
      <Layout>
        <PageMeta title={pageTitle} description={pageDescription} />
        <Header
          bgColor="white"
          logoImg="blackLetters"
          style={{ marginBottom: '0px' }}
        />
        <div css={headerWrapper}>
          <Img
            css={headerImg}
            style={{ position: 'absolute' }}
            fluid={headerImage}
          />
          <H1 css={headerFont}>Corporate Partnerships</H1>
        </div>
        <ContentBox css={paddingCopy}>
          <FocusH3>AT GLOVEWORX, YOUR EMPLOYEES</FocusH3>
          <FocusH3>WILL TRAIN LIKE ATHLETES—</FocusH3>
          <P css={bodyCopy}>
            Building a strong healthy body, a clear mind, and the ability to
            focus and perform like a pro. Gloveworx is a state-of-the-art boxing
            studio and athletic performance facility that combines boxing
            training with strength and conditioning in a format that balances
            personal attention and team-building.
          </P>
        </ContentBox>
        <CorporateBox p="6rem 0 0 0">
          <div style={{ paddingBottom: '6rem' }}>
            <H3>CORPORATE PACKAGE OPTIONS</H3>
          </div>
          <div css={row}>
            <Img css={packageImg} fluid={locationImage} />
            <CorporateRedBox
              h4text={studioName}
              h4LowText="SESSIONS"
              boxText={`Book a group session for your team or clients! You’ll work
        together, bond and get to know each other during the workout of your
        life. Did we mention the state-of-the-art facilities? Boxing is the
        best new networking activity.
        `}
            />
          </div>
          <div css={row}>
            <CorporateRedBox
              h4text="“IN YOUR HOUSE”"
              h4LowText="GROUP SESSION"
              boxText={`Your team too busy to make it to the gym? We’ll create the
        Gloveworx boxing workout in your office. We’ll bring the mitts,
        you bring the sweat! Try it for special occasions or make it a
        monthly team-building ritual.`}
            />
            <Img css={packageImg} fluid={inYourHouse} />
          </div>
          <div css={row}>
            <Img css={packageImg} fluid={dropIn} />
            <CorporateRedBox
              h4text="20% OFF"
              h4LowText="SESSION DROP-INS"
              boxText={`Give your employees maximum individual flexibility with a simple
        discount on our standard drop-in rates. Your employees can
        schedule workouts on their own schedule and work out as often as
        they like!`}
            />
          </div>
        </CorporateBox>
        <ContentBox>
          <FocusH3 css={letsTalk}>LET’S TALK!</FocusH3>
          <FocusH3 style={{ fontWeight: 'normal' }}>
            WE’LL WORK WITH YOU TO CREATE A PACKAGE
          </FocusH3>
          <FocusH3 style={{ fontWeight: 'normal' }}>
            THAT’S RIGHT FOR YOUR TEAM.
          </FocusH3>
          {!this.state.submitted && (
            <CorporateSubmitForm
              apiUrl={apiUrl}
              studioSlug={studioSlug}
              setSubmitTrue={this.setSubmitTrue}
            />
          )}
          {this.state.submitted && (
            <ContentBox>
              <Img
                style={{ width: '10rem', margin: '4rem auto' }}
                fluid={formVerify}
              />
              <BoxP css={formVerifyCss}>
                Congratulations! You are one step closer to creating an exciting
                GWX experience for your co-workers. A representative will reach
                out soon to design your ideal package of options.
              </BoxP>
            </ContentBox>
          )}
        </ContentBox>
      </Layout>
    );
  }
}

CorporateLandingTemplate.propTypes = {
  pageContext: PropTypes.shape({
    studioName: PropTypes.string.isRequired,
    studioSlug: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        apiUrl: PropTypes.string,
      }),
    }),
    headerImage: PropTypes.object.isRequired,
    locationImage: PropTypes.object.isRequired,
    dropIn: PropTypes.object.isRequired,
    formVerify: PropTypes.object.isRequired,
    inYourHouse: PropTypes.object.isRequired,
  }),
};

export default CorporateLandingTemplate;

export const corporateQuery = graphql`
  query($studioImageName: String!) {
    site {
      siteMetadata {
        apiUrl
      }
    }
    locationImage: file(name: { eq: $studioImageName }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    headerImage: file(name: { eq: "teamwork-gloves.original" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    dropIn: file(name: { eq: "drop-in-img.original" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    inYourHouse: file(name: { eq: "in-your-house.original" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    formVerify: file(name: { eq: "form-verify.original" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
