import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { animateScroll } from 'react-scroll';

import { RedBox } from '../../styles/boxes.css';
import { H4 } from '../../styles/headers.css';
import { BoxP } from '../../styles/text.css';

let h4Low = css`
  font-weight: normal;
  margin-bottom: 1.5rem;
`;

const scrollToBottom = () => {
  animateScroll.scrollToBottom();
};

const CorporateRedBox = ({ h4text, h4LowText, boxText }) => {
  return (
    <div
      css={RedBox}
      onClick={() => {
        scrollToBottom();
      }}
      onKeyDown={() => {
        scrollToBottom();
      }}
      role="button"
      tabIndex="0"
    >
      <H4>{h4text}</H4>
      <H4 css={h4Low}>{h4LowText}</H4>
      <BoxP>{boxText}</BoxP>
    </div>
  );
};

export default CorporateRedBox;

CorporateRedBox.propTypes = {
  h4text: PropTypes.string.isRequired,
  h4LowText: PropTypes.string.isRequired,
  boxText: PropTypes.string.isRequired,
};
