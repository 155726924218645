import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { mq } from '../../styles/breakpoints';

import { inputField, smallInputField, messageField } from 'styles/forms.css';
import { textColor } from 'styles/text.css';
import { submitHeavy } from '../../styles/button.css';

let errorMessage = css`
  ${textColor.red}
`;
let formCss = css`
  padding-top: 3rem;
  ${mq.desktop} {
    padding-top: 6rem;
  }
`;
let corporateInput = css`
  ${inputField()}
  font-size= 1rem;
`;

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Email required. '),
  name: Yup.string()
    .min(1, 'invalid name')
    .required('Name required. '),
  company: Yup.string()
    .min(1, 'invalid company')
    .required('Company required. '),
  title: Yup.string(),
  message: Yup.string(),
});

class CorporateSubmitForm extends React.Component {
  /*
   * Adapted from `ReferralForm`
   */

  render() {
    const { apiUrl, setSubmitTrue, studioSlug } = this.props;
    return (
      <Formik
        initialValues={{
          company: '',
          email: '',
          message: '',
          name: '',
          submitted: false,
          title: '',
        }}
        validationSchema={schema}
        onSubmit={(values, { setFieldValue, setSubmitting }) => {
          var data = {
            company_name: values.company,
            email: values.email,
            message: values.message,
            name: values.name,
            company_title: values.title,
            studio: studioSlug,
          };
          return fetch(`${apiUrl}/v1/corporate-programs/`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
            .then(response => {
              setSubmitting(false);
              return response.json();
            })
            .then(function() {
              setFieldValue('submitted', true);
              setSubmitTrue();
            });
        }}
      >
        {({ values, isValid }) => (
          <Form css={formCss}>
            <div style={{ position: 'relative' }}>
              <div>
                <Field
                  css={corporateInput}
                  id="name"
                  type="text"
                  name="name"
                  placeholder="Full Name"
                  disabled={values.submitted}
                />
              </div>
              <div>
                <Field
                  css={corporateInput}
                  id="email"
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  disabled={values.submitted}
                />
              </div>
              <div>
                <Field
                  css={smallInputField}
                  id="company"
                  type="text"
                  name="company"
                  placeholder="Company Name"
                  disabled={values.submitted}
                />

                <Field
                  css={smallInputField}
                  id="title"
                  type="text"
                  name="title"
                  placeholder="Title"
                  disabled={values.submitted}
                />
              </div>
              <div>
                <Field
                  css={messageField}
                  id="message"
                  type="text"
                  name="message"
                  placeholder="Write Your Message Here..."
                  disabled={values.submitted}
                />
              </div>
            </div>
            <div css={errorMessage}>
              <ErrorMessage name="name" />
              <ErrorMessage name="email" />
              <ErrorMessage name="company" />
              <ErrorMessage name="title" />
              <ErrorMessage name="message" />
            </div>
            <div>
              <button type="submit" css={submitHeavy} disabled={!isValid}>
                SUBMIT &gt;
              </button>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

CorporateSubmitForm.propTypes = {
  apiUrl: PropTypes.string.isRequired,
  setSubmitTrue: PropTypes.func.isRequired,
  studioSlug: PropTypes.string.isRequired,
};

export default CorporateSubmitForm;
